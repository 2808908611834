<template>
  <div>

    <b-alert
      variant="danger"
      :show="founder === undefined"
    >
      <h4 class="alert-heading">
        Error fetching founder data
      </h4>
      <div class="alert-body">
        No founder found with this founder id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'founders'}"
        >
          Founders List
        </b-link>
        for other Founders.
      </div>
    </b-alert>

    <template v-if="founder && loaded">
      <b-form @submit.prevent="updateFounderInfo">

        <b-card-code
          title="Profile Image"
        >
          <b-row>
            <b-col sm="12">
              <div
                class="brand-photo"
                style="width: 140px; height: 140px; margin: 20px auto; border-radius: 50%; overflow: hidden; cursor: pointer; border: 1px solid #ccc;"
              >
                <input
                  ref="uploadClientImage"
                  type="file"
                  style="display:none"
                  accept="image/*"
                  @change="handleClientImage($event)"
                >
                <div @click="pickUpClientImage">
                  <img
                    v-if="uploadedImage"
                    v-ghost-img
                    :src="uploadedImage"
                    :alt="founder.name"
                    width="140px"
                    height="140px"
                    class="mx-auto"
                  >
                  <img
                    v-else-if="founder.image"
                    :src="founder.image"
                    :alt="founder.name"
                    width="140px"
                    height="140px"
                    class="mx-auto"
                  >
                  <img
                    v-else
                    src="./../assets/images/user-placeholder.png"
                    width="140px"
                    height="140px"
                    class="mx-auto"
                    :alt="founder.name"
                  >
                </div>
              </div>
            </b-col>

            <!--  -->
            <!-- <b-col md="12">
              <h2 class="double">
                <span class="text-center">Or you can import image from a link</span>
              </h2>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ExternalLinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="imageURL"
                    placeholder="Or you can import image from a link"
                  />
                </b-input-group>
              </b-form-group>
            </b-col> -->

          </b-row>
        </b-card-code>

        <b-card-code
          title="Profile Details"
        >
          <b-row>

            <!-- FULL NAME -->
            <b-col md="4">
              <b-form-group
                label="Full Name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.name"
                    placeholder="Full Name"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- PHONE NUMBER -->
            <b-col md="4">
              <b-form-group
                label="Phone Number"
              >
                <b-form-input
                  v-model="founder.phone"
                  placeholder="Phone Number"
                />
              </b-form-group>
            </b-col>

            <!-- EMAIL ADDRESS -->
            <b-col md="4">
              <b-form-group
                label="Email Address"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.email"
                    placeholder="Email Address"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- BIRTHDATE -->
            <b-col md="4">
              <b-form-group
                label="Birhdate"
              >
                <b-form-datepicker
                  v-model="founder.birthday"
                  :initial-date="founder.birthday"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  placeholder="Birhdate"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>

            <!-- GENDER -->
            <b-col md="4">
              <b-form-group
                label="Gender"
              >
                <div class="form-label-group">
                  <v-select
                    v-model="founder.gender"
                    placeholder="Gender"
                    :options="['Male', 'Female', 'Other']"
                  />
                </div>
              </b-form-group>
            </b-col>

            <!-- IMAGE LINK -->
            <b-col md="4">
              <b-form-group
                label="Image Link"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ExternalLinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="imageURL"
                    placeholder="Or you can import image from a link"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- <b-col md="12">
                            <b-form-group
                                label="Long Description"
                            >
                                <div class="form-label-group">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon icon="InfoIcon" />
                                        </b-input-group-prepend>
                                        <b-form-textarea
                                            placeholder="Long Description"
                                            v-model="company.longDescription"
                                            rows="4"
                                        />
                                    </b-input-group>
                                </div>
                            </b-form-group>
                        </b-col> -->
          </b-row>
        </b-card-code>

        <b-card-code
          title="Social Links"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Facebook"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FacebookIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.links.facebook"
                    placeholder="Facebook"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Linkedin"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkedinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.links.linkedin"
                    placeholder="Linkedin"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Twitter"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TwitterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.links.twitter"
                    placeholder="Twitter"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Instagram"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="InstagramIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.links.instagram"
                    placeholder="Instagram"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Youtube"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="YoutubeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.links.youtube"
                    placeholder="Youtube"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Tiktok"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TiktokIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="founder.links.tiktok"
                    placeholder="Tiktok"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card-code>

        <b-row>
          <b-col
            xs="12"
            class="text-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submit"
            >
              <template v-if="submit">
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </template>
              <template v-else>
                Submit
              </template>
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </template>

    <b-modal
      v-model="showUserImageCropModal"
      centered
      title="Edit Image"
      :hide-footer="true"
    >
      <b-card-text>
        <section class="modal-card-body text-center">
          <vue-croppie
            ref="croppieRef"
            :enable-orientation="true"
            :mouse-wheel-zoom="true"
            :enable-resize="false"
            :boundary="{ width: 300, height: 300 }"
            :viewport="{ width: 200, height: 200, type: 'circle' }"
            @result="result"
          />
        </section>
        <footer class="modal-card-foot justify-content-center">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="mr-1"
            variant="primary"
            pill
            @click="rotate(-90)"
          >
            Rotate Left
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="mr-1"
            variant="info"
            pill
            @click="rotate(90)"
          >
            Rotate Right
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="ml-0"
            variant="success"
            pill
            @click="crop"
          >
            Save Changes
          </b-button>
        </footer>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually
Vue.use(VueCroppie)

import {
  BRow, BCol, BAlert, BLink, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormDatepicker, BFormTextarea, BSpinner, BModal, BCardText,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BForm,
    BModal,
    BButton,
    BSpinner,
    BCardText,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTextarea,
    BCardCode,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ['username'],
  data() {
    return {
        submit: false,
        loaded: false,
        imageURL: null,
        uploadedImage: null,
        showUserImageCropModal: false,
    }
  },
  computed: {
    ...mapGetters('founders', ['founder']),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getData()
    })
  },
  created() {
      if (!this.founder) {
        this.getData()
      }
  },
  methods: {
    ...mapActions('founders', ['getFounderInfoAction', 'updateFounderAction']),
    pickUpClientImage () {
      this.$refs.uploadClientImage.click();
	  },
    result (output) {
      this.uploadedImage = output;
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
		},
    crop () {
      let options = {
          format: 'jpeg',
          circle: false,
      };

      this.$refs.croppieRef.result(options, output => {
        this.uploadedImage = output;
      });

      this.showUserImageCropModal = false;
    },
    handleClientImage (event) {
      const files = event.target.files
      let reader = new FileReader()
      reader.addEventListener("load", () => {
        this.$refs.croppieRef.bind({
          url: reader.result
        })
      })
      reader.readAsDataURL(files[0])
      this.showUserImageCropModal = true
    },
    getData() {
        this.getFounderInfoAction(this.username).finally(() => {
          this.loaded = true

          if (this.founder && this.founder.birthday) {
              this.$store.state['founders'].founder.birthday = moment(this.founder.birthday, 'MM/DD/YYYY').format('YYYY-MM-DD')
          }
        })
    },
    updateFounderInfo() {
        this.submit = true

        const founder = {
            name: this.founder.name,
            email: this.founder.email,
            // image: this.founder.image,
            phone: this.founder.phone,
            gender: this.founder.gender,
            birthday: this.founder.birthday,
            facebook: this.founder.links.facebook,
            linkedin: this.founder.links.linkedin,
            twitter: this.founder.links.twitter,
            instagram: this.founder.links.instagram,
            youtube: this.founder.links.youtube,
            tiktok: this.founder.links.tiktok,
            // status: this.founder.name,
        }

        if (this.uploadedImage) {
          founder.image = this.uploadedImage
        }

        if (this.imageURL) {
          founder.image = this.imageURL
        }

        this.updateFounderAction({ username: this.founder.username, data: founder }).then(() => {
          this.showToast('Data Updated Successfully!')
        }).catch(({ response }) => {
          this.showToast(response.data.message, 'danger', 'AlertTriangleIcon')
        }).finally(() => {
            if (this.founder && this.founder.birthday) {
                this.$store.state['founders'].founder.birthday = moment(this.founder.birthday, 'MM/DD/YYYY').format('YYYY-MM-DD')
            }
            this.submit = false
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
